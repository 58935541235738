import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostPlaceholder } from '@components/PostPlaceholder';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "postplaceholder"
    }}>{`PostPlaceholder`}</h1>
    <Playground __position={0} __code={'<PostPlaceholder />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      PostPlaceholder,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <PostPlaceholder mdxType="PostPlaceholder" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      